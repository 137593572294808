<!-- Settings -->
<template>
    <div class="card card-custom">
        <div class="card-body p-0">
            <!--begin: Settings-->
            <div class="wizard wizard-1" id="kt_wizard_v1" data-wizard-state="step-first" data-wizard-clickable="true">
                <!--begin: Settings Body-->
                <div class="row justify-content-center">
                    <div class="col-xl-12">
                            <!--begin: Wizard Step 1-->
                            <div class="pb-5" data-wizard-type="step-content" data-wizard-state="current">
                                <div class="row view-header mb-10">
                                    <div class="col-sm-9">
                                        <h3 class="font-weight-bold text-dark"><i class="flaticon-user settingicon" style="color:#187DE4"></i> Store Details</h3>
                                    </div>
                                    <div class="col-sm-3">
                                        <div class="text-right"> 
                                          <b-button class="rounded-0 btn btn-sm btn-primary font-weight-bold text-uppercase" variant="primary" @click="editWebsite(website_details.id)"><i class="fa fa-pen"></i>Edit
                                          </b-button>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-3 text-center">
                                        <div class="row">
                                            <div class="col-xl-12">
                                                <div class="form-group">
                                                    <span class="store-image" v-loadimage="website_details.website_logo"></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                        
                                <div class="col-xl-9">
                                    <div class="row">
                                    <div class="col-xl-12 website-title-width">
                                        <div class="form-group">
                                            <label><span><b>Store Name </b></span></label><span> {{ website_details.website_name }}</span>
                                        </div>
                                    
                                        <div class="form-group">
                                            <label><span><b>Store Prefix</b></span></label><span> {{ website_details.website_prefix }}</span>
                                        </div>
                                    
                                        <div class="form-group">
                                            <label><span><b>Store Blog ID</b></span></label><span> {{ website_details.website_id }}</span>
                                        </div>
                                   
                                        <div class="form-group">
                                            <label><span><b>Store URL </b></span></label><span> {{ website_details.website_url }}</span>
                                        </div>
                                   
                                        <div class="form-group">
                                            <label><span><b>Store Endpoint </b></span></label><span> {{ website_details.website_endpoint }}</span>
                                        </div>
                                   
                                        <div class="form-group">
                                            <label><span><b>Consumer Key </b></span></label><span> {{ website_details.consumer_key }}</span>
                                        </div>
                                    
                                        <div class="form-group">
                                            <label><span><b>Consumer Secret</b></span></label><span> {{ website_details.consumer_secret }}</span>
                                        </div>
                                    
                                        <div class="form-group">
                                            <label><span><b>Status </b></span></label>
                                            <span v-if="website_details.is_active === 1" class="label label-light-success label-inline font-weight-bold label-lg">Active</span>
                                            <span v-else class="label label-light-danger label-inline font-weight-bold label-lg"> In Active</span>
                                            
                                        </div>
                                    </div>
                                </div>
                                </div>
                                </div>
                               
                            </div>
                    </div>
                </div>
                <!--end: Settings Body-->
            </div>
        </div>
        <!--end: Settings-->
    </div>
</template>


<style>
.settingicon{font-size:20px;}
.website-title-width div label{width:20%;}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {getWebsiteDetails} from "@/api/website";

export default {
  name: 'view-website',
  data() {
    return {
      loading: false,
      website_details:[],
    }
  },
  components: {},
  created() {
    this.fetchData(1)   
  },
  methods: {
    fetchData() {
     this.loading = true
  	 getWebsiteDetails(this.$route.params.websiteId).then(response => {
        this.website_details = response.data.data
        this.loading = false
     });
    },
     editWebsite(website_id){
        this.$router.push({path:'/website/edit/'+website_id})
    }
  },
  mounted() {
     
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Dashboard", route: "/dashboard" },
      { title: "Stores", route: "/website/list" },
      { title: "View Store" }
     
    ]);

  },


};
</script>
